const Footer = () => {
    return (
            <footer id="footer" className="footer-1 footer-copyright container">
                <div className="row text-left">
                    <p>© Copyright iO Academy 2022</p>
                </div>
            </footer>
    )
}

export default Footer